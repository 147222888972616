import { getPropertyRenters, updatePropertyRenters } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'

const schema = [
  {
    type: 'autocomplete',
    autoCompleteType: 'contact',
    multiple: true,
    name: 'renters',
    label: 'Huurders',
    placeholder: 'Selecteer huurders'
  }
]

const actions = {
  async load (propertyId) {
    try {
      const response = await getPropertyRenters(propertyId)
      // FIXME: [BE] the response is an array, it should be an object with a key value pair. { renters: [...] }
      return { renters: response.data }
    } catch (error) {
      errorModal('Huurders kunnen niet worden geladen')
      throw error
    }
  },
  async submit (propertyId, data) {
    try {
      const { renters } = data
      const relation_ids = renters.map(renter => renter.id)
      const response = await updatePropertyRenters(propertyId, { relation_ids })
      successModal('Huurders zijn succesvol geüpdatet')
      return response
    } catch (error) {
      if (error?.response?.data?.code?.[0] === 'ANOTHER_CONTACT_WITH_SAME_EMAIL_ADDRESS_HAS_USER_ACCOUNT') {
        const email = error?.response?.data?.message?.[0] || '-'
        errorModal(`Kan huurders niet aanpassen. Er bestaat een ander contact met e-mailadres ${email} en een gekoppeld klantenportaal. Gelieve dit contact te selecteren of de contacten samen te voegen.`)
      } else {
        errorModal('Kan huurders niet aanpassen, gelieve opnieuw te proberen.')
      }
      throw error
    }
  }
}

export default {
  heading: 'Huurders',
  schema,
  actions
}
